import { QueryClientConfig } from "@tanstack/react-query";

const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            // suspense: true,
            useErrorBoundary: true,
            // use default options
            // because better security
            staleTime: 1000 * 60 * 5, // 5 minutes
            cacheTime: 1000 * 60 * 60 * 1, // 1 hours
        },
    },
}
export default queryClientConfig