import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";

import config from "./slicemachine.config.json";

/**
 * The project's Prismic repository name.
 */
export const repositoryName = config.repositoryName;

/**
 * A list of Route Resolver objects that define how a document's `url` field
 * is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
// TODO: Update the routes array to match your project's route structure.
const routes: prismic.ClientConfig["routes"] = [
  {
    type: "homepage-type",
    path: "/",
  },
  {
    type: "test_type",
    path: "/:uid",
  },
  {
    type: "about-page",
    path: "/about"
  },
  // {
  //   type: "sector-size-test",
  //   path: "/casa/sector-size-test/:uid"
  // },
  {
    type: "cross-section-test",
    path: "/casa/cross-section-test/:uid"
  },
  {
    type: "orientation-test",
    path: "/casa/orientation-test/:uid"
  },
  {
    type: "rapid-rotation-test",
    path: "/casa/rapid-rotation-test/:uid"

  },
  {
    type: "viewpoint-test",
    path: "/casa/viewpoint-test/:uid"

  }
  , {
    type: "anva_test",
    path: "/anva/anva-test/:uid"

  }
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
